import React, { useEffect, useState, useRef } from "react"
import Layout from "../../components/shared-layout";
import { Alert, Box, Checkbox, Chip, FormControlLabel, Grow, IconButton, Skeleton, TextField, Typography } from "@mui/material";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
// @ts-ignore
import SnowMountain from "../../images/snow-mountain.jpg";
import { ITEM_PERFECT_INLINED, PADDING_HORIZONTAL, THEME_YELLOW } from "../../constants/style";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import VmButton from "../../components/shared-button";
import useStores from "../../hooks/use-stores";
import { navigate } from "gatsby";
import { FilterType, Language, ListLayoutType, ProductType, SearchCategory } from "../../constants/options";
import SlideDown from "react-slidedown";
import 'react-slidedown/lib/slidedown.css';
import ProductCard from "../../components/product-card";
import ListAltIcon from '@mui/icons-material/ListAlt';
import { isWeb } from "../../utilities/platform";
import { GetLanguage } from "../../utilities/general";
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { IsEmptyStr } from "../../utilities/field-validation";

const DayTripsPage = observer(({ location }: { location: any }) => {
  const { t }: any = useTranslation();
  const { productStore } = useStores();
  const [productList, setProductList] = useState<any[]>([]);
  const [productListWithoutSearchKey, setProductListWithoutSearchKey] = useState<any[]>([]);
  const [searchKey, setSearchKey] = useState<string>("");
  const [expandFilter, setExpandFilter] = useState<boolean>(true);
  const [showMoreDest, setShowMoreDest] = useState<boolean>(false);
  const [showMoreFilter, setShowMoreFilter] = useState<boolean>(false);
  const [filterDestList, setFilterDestList] = useState<string[]>([]);
  const [filterCatList, setFilterCatList] = useState<string[]>([]);
  const [filterLangList, setFilterLangList] = useState<string[]>([]);
  const [filterDisplayChipList, setFilterDisplayChipList] = useState<any>([]);
  const [refresh, setRefresh] = useState<any>([]);
  const mainContentRef = useRef(null);

  useEffect(() => {
    productStore.searchProductByCategory(SearchCategory.PRODUCT_TYPE, ProductType.DAY_TRIP, GetLanguage())
      .then(() => {
        setProductList(productStore.productListByCategory);
        setProductListWithoutSearchKey(productStore.productListByCategory);
        if (mainContentRef.current) {
          // @ts-ignore
          mainContentRef.current.scrollIntoView(({ behavior: 'smooth' }));
        }
      });
  }, []);

  // Filter Checkboxes
  const onTickFilterDest = (event: React.ChangeEvent<HTMLInputElement>) => {
    let id = event.target.value as string;
    const targetFilter = productStore.listFilterItems.find((f: any) => f.filterCategory == FilterType.REGION)
      .items.find((f: any) => f.id == +id);
    if (!filterDestList.includes(id)) {
      filterDestList.push(id);
      filterDisplayChipList.push({ id: targetFilter.id, label: targetFilter.name, type: FilterType.REGION });
    } else {
      let index = filterDestList.indexOf(id);
      filterDestList.splice(index, 1);
      // remove chip
      let chipIdx = filterDisplayChipList.map((f: any) => f.id).indexOf(+id);
      filterDisplayChipList.splice(chipIdx, 1);
    }
    onTriggerFilter();
    setRefresh([]);
  };
  const onTickFilterCat = (event: React.ChangeEvent<HTMLInputElement>) => {
    let id = event.target.value as string;
    const targetFilter = productStore.listFilterItems.find((f: any) => f.filterCategory == FilterType.TAG)
      .items.find((f: any) => f.id == +id);
    if (!filterCatList.includes(id)) {
      filterCatList.push(id);
      filterDisplayChipList.push({ id: targetFilter.id, label: targetFilter.name, type: FilterType.TAG });
    } else {
      let index = filterCatList.indexOf(id);
      filterCatList.splice(index, 1);
      // remove chip
      let chipIdx = filterDisplayChipList.map((f: any) => f.id).indexOf(+id);
      filterDisplayChipList.splice(chipIdx, 1);
    }
    onTriggerFilter();
    setRefresh([]);
  };
  const onTickFilterLang = (event: React.ChangeEvent<HTMLInputElement>) => {
    let id = event.target.value as string;
    const targetFilter = productStore.listFilterItems.find((f: any) => f.filterCategory == FilterType.LANG)
      .items.find((f: any) => f.id == +id);
    if (!filterLangList.includes(id)) {
      filterLangList.push(id);
      filterDisplayChipList.push({ id: targetFilter.id, label: targetFilter.name, type: FilterType.LANG });
    } else {
      let index = filterLangList.indexOf(id);
      filterLangList.splice(index, 1);
      // remove chip
      let chipIdx = filterDisplayChipList.map((f: any) => f.id).indexOf(+id);
      filterDisplayChipList.splice(chipIdx, 1);
    }
    onTriggerFilter();
    setRefresh([]);
  };
  const onTriggerFilter = () => {
    let filteredList: any[] = [];
    if (productStore.productListByCategory.length > 0) filteredList = productStore.productListByCategory;
    const isDestFiltered = (f: any) => {
      return f.filterDestination.includes(",") ? f.filterDestination.split(',').some((c: any) => filterDestList.includes(c))
        : filterDestList.includes(f.filterDestination);
    }
    const isCategoryFiltered = (f: any) => {
      return f.filterTag && (f.filterTag.includes(",") ? f.filterTag.split(',').some((c: any) => filterCatList.includes(c))
        : filterCatList.includes(f.filterTag));
    }
    const isLangFiltered = (f: any) => {
      return f.webLanguage.includes(",") ? f.webLanguage.split(',').some((l: any) => filterLangList.includes(l))
        : filterLangList.includes(f.webLanguage);
    }
    filteredList = filteredList.filter((f: any) => isDestFiltered(f) || isCategoryFiltered(f) || isLangFiltered(f));
    if (filterDestList.length == 0 && filterCatList.length == 0 && filterLangList.length == 0) {
      filteredList = productStore.productListByCategory;
    }
    setProductList(filteredList);
    setProductListWithoutSearchKey(filteredList);
    onSearchProducts(searchKey, filteredList);
  }
  // End Filter Checkboxes
  const onSearchProducts = (searchKey: string, productList?: any[]) => {
    setSearchKey(searchKey);
    let list = productList ? productList : productListWithoutSearchKey;
    if (IsEmptyStr(searchKey)) setProductList(list);
    else {
      let resultList = list;
      if (GetLanguage() == Language.ENGLISH) resultList = resultList.filter((p: any) => p.searchTerms.toLowerCase().includes(searchKey.toLowerCase()));
      else resultList = resultList.filter((p: any) => p.searchTerms && p.searchTerms.includes(searchKey));
      setProductList(resultList);
    }
  }

  return (
    <Layout pageName={t('DAY_TRIPS')}
      showSearchButton
      onSearch={onSearchProducts}
      breadCrumb={[
        { to: location.state && location.state.prevPath ? location.state.prevPath : "/", label: location.state && location.state.prevPath ? location.state.prevPath : t('HOME') },
        { to: "", label: t('DAY_TRIPS') }
      ]}>
      <span ref={mainContentRef} />
      <Box className={PADDING_HORIZONTAL}>
        {productStore.loadingList ? <>
          <Skeleton variant="rectangular" width="100%" height={80} />
          <Skeleton variant="rectangular" width="100%" height={40} className="mt-6 mb-10" />
          <Skeleton variant="rectangular" width="100%" height={100} className="mb-4" />
          <Skeleton variant="rectangular" width="100%" height={100} className="mb-4" />
          <Skeleton variant="rectangular" width="100%" height={100} className="mb-4" />
        </> : <>
          {/* Filter */}
          <Box className="shadow-lg mb-4">
            <Box>
              <VmButton className="w-full" onClick={() => setExpandFilter(!expandFilter)}>
                <Box sx={ITEM_PERFECT_INLINED} className="justify-between">
                  <Box sx={ITEM_PERFECT_INLINED}>
                    <SettingsOutlinedIcon />
                    <Typography className="ml-2">{t('FILTER')}</Typography>
                  </Box>
                  {expandFilter ? <ExpandLessIcon color="inherit" />
                    : <ExpandMoreIcon color="inherit" />}
                </Box>
              </VmButton>
            </Box>
            <Box className="px-8">
              {productStore.listFilterItems.length > 0 &&
                <SlideDown closed={!expandFilter}>
                  {/* Popular Destination Filter */}
                  <Box className="py-4">
                    <SlideDown closed={showMoreDest}>
                      <Typography mr={2}>{t('POPULAR_DESTINATION')}:</Typography>
                      <Box className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-5 2xl:grid-cols-6">
                        {productStore.listFilterItems.find((f: any) => f.filterCategory == FilterType.REGION)
                          .items.filter((f: any) => f.name !== "").slice(0, 12).map((f: any, i: number) => (
                            <FormControlLabel
                              key={`filter_dest_${i}`}
                              className="text-gray-400"
                              label={f.name + ` (${f.count})`}
                              control={
                                <Checkbox
                                  value={f.id}
                                  checked={filterDestList.includes(f.id.toString()) ? true : false}
                                  onChange={onTickFilterDest}
                                  sx={{ color: "gray", '&.Mui-checked': { color: THEME_YELLOW } }}
                                />}
                            />
                          ))}
                      </Box>
                    </SlideDown>
                    {showMoreDest && <>
                      <Typography mr={2}>{t('POPULAR_DESTINATION')}:</Typography>
                      <Box className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-5 2xl:grid-cols-6">
                        {productStore.listFilterItems.find((f: any) => f.filterCategory == FilterType.REGION)
                          .items.filter((f: any, i: number) => f.name !== "").map((f: any, i: number) => (
                            <FormControlLabel
                              key={`filter_dest_${i}`}
                              className="text-gray-400"
                              label={f.name + ` (${f.count})`}
                              control={
                                <Checkbox
                                  value={f.id}
                                  checked={filterDestList.includes(f.id.toString()) ? true : false}
                                  onChange={onTickFilterDest}
                                  sx={{ color: "gray", '&.Mui-checked': { color: THEME_YELLOW } }}
                                />}
                            />
                          ))}
                      </Box>
                    </>}
                    <Box sx={ITEM_PERFECT_INLINED} className="justify-end">
                      {productStore.listFilterItems.find((f: any) => f.filterCategory == FilterType.REGION)
                        .items.filter((f: any) => f.name !== "").length > 12 && <button className="mr-4" onClick={() => setShowMoreDest(!showMoreDest)}>
                          <Box sx={ITEM_PERFECT_INLINED}>
                            <Typography className="mr-2">{showMoreDest ? t('SHOW_LESS') : t('SHOW_MORE')}</Typography>
                            {showMoreDest ? <ExpandLessIcon color="inherit" /> : <ExpandMoreIcon color="inherit" />}
                          </Box>
                        </button>}
                      {!showMoreFilter && <button onClick={() => setShowMoreFilter(true)}>
                        <Box sx={ITEM_PERFECT_INLINED}>
                          <Typography className="mr-2">{t('SHOW_MORE')}</Typography>
                          <ExpandMoreIcon color="inherit" />
                        </Box>
                      </button>}
                    </Box>
                  </Box>
                  <SlideDown closed={!showMoreFilter} className="pb-6">
                    {/* Category Filter */}
                    <Box className="py-4 border-t">
                      <Typography mr={2}>{t('CATEGORY')}:</Typography>
                      <Box className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-5 2xl:grid-cols-6">
                        {productStore.listFilterItems.find((f: any) => f.filterCategory == FilterType.TAG)
                          .items.map((f: any, i: number) => (
                            <FormControlLabel
                              key={`filter_cat_${i}`}
                              className="text-gray-400"
                              label={f.name + ` (${f.count})`}
                              control={
                                <Checkbox
                                  value={f.id}
                                  checked={filterCatList.includes(f.id.toString()) ? true : false}
                                  onChange={onTickFilterCat}
                                  sx={{ color: "gray", '&.Mui-checked': { color: THEME_YELLOW } }}
                                />}
                            />
                          ))}
                      </Box>
                    </Box>
                    {/* Langauge Filter */}
                    <Box className="pt-4 border-t">
                      <Typography mr={2}>{t('LANGUAGE')}:</Typography>
                      <Box className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-5 2xl:grid-cols-6">
                        {productStore.listFilterItems.find((f: any) => f.filterCategory == FilterType.LANG)
                          .items.map((f: any, i: number) => (
                            <FormControlLabel
                              key={`filter_lang_${i}`}
                              className="text-gray-400"
                              label={f.name + ` (${f.count})`}
                              control={
                                <Checkbox
                                  value={f.id}
                                  checked={filterLangList.includes(f.id.toString()) ? true : false}
                                  onChange={onTickFilterLang}
                                  sx={{ color: "gray", '&.Mui-checked': { color: THEME_YELLOW } }}
                                />}
                            />
                          ))}
                      </Box>
                    </Box>
                    <Box sx={ITEM_PERFECT_INLINED} className="justify-end">
                      <button onClick={() => setShowMoreFilter(false)}>
                        <Box sx={ITEM_PERFECT_INLINED}>
                          <Typography className="mr-2">{t('SHOW_LESS')}</Typography>
                          <ExpandLessIcon color="inherit" />
                        </Box>
                      </button>
                    </Box>
                  </SlideDown>
                </SlideDown>}
            </Box>
          </Box>
          {/* End Filter */}
          {productStore.productListBySearchKey.length == 0 && productStore.productListByCategory.length == 0 ?
            <><VmButton onClick={() => navigate("/")}>{t('CONTINUE_SHOPPING')}</VmButton></>
            : <Box>
              <Box sx={ITEM_PERFECT_INLINED} className="shadow-lg p-4 mb-4 bg-gray-50">
                <ListAltIcon />
                <Box ml={1}>{productList.length} {t('RESULTS_FOUND')}</Box>
              </Box>
              {/* Start Chips */}
              {!IsEmptyStr(searchKey) && <Box sx={ITEM_PERFECT_INLINED} className="mb-2">
                <p className="mr-2 font-bold">{t('SEARCH_BY')}: </p>
                <Chip color="success" sx={{ mr: 1, background: 'white' }} label={searchKey} variant="outlined" onDelete={() => onSearchProducts("")} />
              </Box>}
              {filterDisplayChipList.filter((key: any) => key.type == FilterType.REGION).length > 0 &&
                <Box className="py-2">
                  <Box sx={ITEM_PERFECT_INLINED}>
                    <p className="mr-2 font-bold">{t('POPULAR_DESTINATION')}: </p>
                    {filterDisplayChipList.filter((key: any) => key.type == FilterType.REGION)
                      .map((key: any, i: number) =>
                        <Chip color="primary" sx={{ mr: 1, background: 'white' }}
                          key={`key_${i}`} label={key.label} variant="outlined"
                          /* @ts-ignore */
                          onDelete={() => onTickFilterDest({ target: { value: `${key.id}` } })}
                        />)}
                  </Box>
                </Box>}
              {filterDisplayChipList.filter((key: any) => key.type == FilterType.TAG).length > 0 &&
                <Box className="py-2">
                  <Box sx={ITEM_PERFECT_INLINED}>
                    <p className="mr-2 font-bold">{t('CATEGORY')}: </p>
                    {filterDisplayChipList.filter((key: any) => key.type == FilterType.TAG)
                      .map((key: any, i: number) =>
                        <Chip color="warning" sx={{ mr: 1, background: 'white' }}
                          key={`key_${i}`} label={key.label} variant="outlined"
                          /* @ts-ignore */
                          onDelete={() => onTickFilterCat({ target: { value: `${key.id}` } })}
                        />)}
                  </Box>
                </Box>}
              {filterDisplayChipList.filter((key: any) => key.type == FilterType.LANG).length > 0 &&
                <Box className="py-2">
                  <Box sx={ITEM_PERFECT_INLINED}>
                    <p className="mr-2 font-bold">{t('LANGUAGE')}: </p>
                    {filterDisplayChipList.filter((key: any) => key.type == FilterType.LANG)
                      .map((key: any, i: number) =>
                        <Chip color="secondary" sx={{ mr: 1, background: 'white' }}
                          key={`key_${i}`} label={key.label} variant="outlined"
                          /* @ts-ignore */
                          onDelete={() => onTickFilterLang({ target: { value: `${key.id}` } })}
                        />)}
                  </Box>
                </Box>}
              {/* End Chips */}
              {filterDisplayChipList.length > 0 && <Box className="mt-2" />}
              {productList.map((item: any, i: number) => (
                <ProductCard
                  key={`product_only_${i}`}
                  mode={item.groupProducts ? "supplier" : "product"}
                  product={item}
                  onClick={() => navigate(`/product-detail?productId=${item.tid}`, { state: { prevPath: isWeb ? location.pathname : '' } })}
                />))}
            </Box>}
        </>}
      </Box>
    </Layout>
  )
});

export default DayTripsPage;
